var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.product.feature_groups.length ||
    _setup.isShowUserHelp ||
    (_setup.software && _setup.software.data.length > 0)
  )?_c('div',{staticClass:"bg-white md:px-[7.777%] xl:px-[8.35%] 3xl:px-[152px]",attrs:{"id":"product-specifications"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"bg-white"},[_c('UiTabs',[(_vm.product.feature_groups.length)?_c('UiTab',{attrs:{"name":_vm.$t('specification')}},[_c(_setup.ProductFeatures,{attrs:{"data":{
              items: _vm.product.feature_groups,
            }}})],1):_vm._e(),_vm._v(" "),(_setup.isShowUserHelp)?_c('UiTab',{attrs:{"name":_vm.$t('user_help')}},[_c(_setup.ProductUserHelp,{attrs:{"manuals":_vm.product.documents.manuals,"videos":_vm.product.documents.videos,"faq-items":_vm.product.faqs}})],1):_vm._e(),_vm._v(" "),(_setup.software && _setup.software.data.length > 0)?_c('UiTab',{attrs:{"name":_vm.$t('software')}},[_c(_setup.ProductSoftware,{attrs:{"items":_setup.software.data}})],1):_vm._e()],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }