import { useProductContent } from '~/composables';
// TODO: переработать компонент CatalogProductCard, чтобы она принимала продукт и вызывала внутри useProductContent
// либо снова прокинуть хелперы, чтобы использовать их в сценариях с массива
const getProductContent = (product: any) => {
  const { image, description, link, tags } = useProductContent(product);
  return {
    image: image.value,
    description: description.value,
    link: link.value,
    tags: tags.value,
  };
};

export default getProductContent;
