import { render, staticRenderFns } from "./ProductBenefits.vue?vue&type=template&id=876f5404&scoped=true"
import script from "./ProductBenefits.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductBenefits.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProductBenefits.vue?vue&type=style&index=0&id=876f5404&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "876f5404",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductBenefitsItem: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@4/components/sections/productSection/ProductBenefitsItem.vue').default})
