import {
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
  prepareDynamicBlock,
} from '@webplatform/frontkit/dist/helpers';

export {
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
  prepareDynamicBlock,
};
