import { render, staticRenderFns } from "./PartnersSection.vue?vue&type=template&id=7d8615ac&scoped=true"
import script from "./PartnersSection.vue?vue&type=script&setup=true&lang=ts"
export * from "./PartnersSection.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PartnersSection.vue?vue&type=style&index=0&id=7d8615ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d8615ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSectionTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@4/components/ui/UiSectionTitle.vue').default,UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@4/components/ui/UiLink.vue').default})
